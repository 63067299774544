@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: "Noto Sans", sans-serif;
	font-style: normal;
	font-optical-sizing: auto;
}

.container {
	display: flex;
	flex-direction: row;
	height: 100vh;
}

.sidebar {
	display: flex;
	flex-direction: column;
	width: 240px;
	padding: 16px;
	height: 100%;
	justify-content: space-between;
	background: radial-gradient(59.68% 59.68% at 20.05% 20.14%, #002675 33.33%, #0A0A40 100%);
}

.logo-and-links {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.links {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.link-container {
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: 40px;
	align-items: center;
}

.link {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-align: left;
	color: #FFFFFF;
}

.content-container {
	flex: 1;
	padding: 24px 24px;
	background: #F2F4F8;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.page-title {
	display: inline-block;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
	text-align: left;
	color: #002675;
	margin-bottom: 32px;
	border-bottom: 3px solid #00EEEE
}

.form-horizontal {
	max-width: 460px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.readonly {
	background: #E6E6E6;
	border: 1px solid #A3A3A3;
	color: #A3A3A3;
	opacity: 0.8;
}

.readonly-input {
	background-color:  #E6E6E6;
	cursor: not-allowed;
}

.buttons {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
}

.button {
	display: inline-block;
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-align: center;
	color: #002675;
}

.secondary {
	background: transparent;

}

.primary {
	background-color: #00EEEE;
}

.logout {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: #FFFFFF;
}

.account-security-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.account-security-title {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-align: left;
	color: #0069BF;
}

.account-security-description {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.2px;
	text-align: left;
	color: #002675;

}

.account-security-link {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.2px;
	text-align: left;
	color: #FF00FF;
}

.link-header {
	font-family: Fedra Mono Std;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	color: #00EEEE;
}

.abrantix-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.abrantix-logo {
	width: 160px;
	height: 47px;
}

.content-message-container {
	display: inline-block;
}

.token-container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.token-container-header {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	text-align: left;
	color: #002675;
}

.token-instance {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.languages-container {
	box-sizing: content-box;
	transform: translateY(100%);
	left: -1px;
	width: 101%;
	bottom: 0;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	background: #E6F7FE;
	border: 2px solid  #E6F7FE;
	gap: 0;
	z-index: 10;
}

.pointer {
	cursor: pointer;
}

.language-selection {
	display: flex;
	height: 46px;
	width: 100%;
	background: #E6F7FE;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.2px;
	text-align: left;
	cursor: pointer;
}

.language-text {
	padding-left: 16px;
	color: #000000;
}